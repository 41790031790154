import Toastify from 'toastify-js';
import { checkToast, crossToast } from '~/static/icons';

export const toasts = {
    successToast(payload) {
        Toastify({
            text: payload.message || 'Success!',
            position: 'center',
            className:
                'toastify-content border border-[#008744] items-center text-sm ' +
                    payload.classNames || '',
            duration: payload.duration || 3500,
            style: {
                padding: '16px 24px',
                color: 'black',
                display: 'flex',
                borderRadius: '12px',
                gap: '12px',
                borderColor: '#008744',
                background: '#E5F5E8'
            },
            avatar: payload.icon || checkToast
        }).showToast();
    },

    failureToast(payload) {
        Toastify({
            text: payload.message || 'Failed!',
            position: 'center',
            className: 'toastify-content border items-center text-sm ' + payload.classNames || '',
            duration: payload.duration || 3500,
            style: {
                padding: '16px 24px',
                color: '#db4d4d',
                display: 'flex',
                borderRadius: '12px',
                gap: '12px',
                borderColor: '#FCA5A5',
                background: '#FBEAED'
            },
            avatar: payload.icon || crossToast
        }).showToast();
    }
};

const install = (app) => {
    app.config.globalProperties.$t = toasts;
};

const isEmailValid = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

const isPhoneValid = async (countryCode, phone) => {
    if (phone.trim().length === 0) {
        return false;
    }

    const validPhoneRegex = /^[0-9]+$/;
    if (!phone.trim().match(validPhoneRegex)) {
        return false;
    }

    const countryAbbreviation = countryCode.abbreviation;

    try {
        const phoneNumberValidator = await import('google-libphonenumber');
        const phoneUtil = phoneNumberValidator.PhoneNumberUtil.getInstance();
        const isValid = phoneUtil.isValidNumberForRegion(
            phoneUtil.parse('+' + countryCode.calling_code + phone, countryAbbreviation),
            countryAbbreviation
        );

        if (!isValid) {
            return false;
        }

        return true;
    } catch (event) {
        return false;
    }
};

const validateContact = async ({ contactType, email, phone, countryCode, api }) => {
    const response = {
        isValid: true,
        isExisting: false,
        message: ''
    };

    if (contactType === 'email' && !isEmailValid(email)) {
        response.isValid = false;
        response.isExisting = false;
        response.message = 'This email doesn’t seem right to us. Kindly check again.';

        return response;
    }

    if (contactType === 'phone' && !isPhoneValid(countryCode, phone)) {
        response.isValid = false;
        response.isExisting = false;
        response.message = 'This phone number doesn’t seem right to us. Kindly check again.';

        return response;
    }

    try {
        const body = {
            contact_type: contactType
        };

        if (contactType === 'email') {
            body.email = email.trim();
        } else if (contactType === 'phone') {
            body.country_code = countryCode.calling_code;
            body.phone = phone;
        }

        const {
            data: { data, state }
        } = await api.auth.validateContact(body);

        // To handle invalid email/phone
        if (!state) {
            if (contactType === 'email') {
                response.isValid = false;
                response.isExisting = false;
                response.message = 'This email doesn’t seem right to us. Kindly check again.';
            } else {
                response.isValid = false;
                response.isExisting = false;
                response.message =
                    'This phone number doesn’t seem right to us. Kindly check again.';
            }

            return response;
        }

        // handle yocket email
        if (data && data.is_yocket_email) {
            const toastPayload = {
                message: `Yocket is already taken by us! Try another email.`
            };
            toasts.failureToast(toastPayload);

            response.isValid = false;
            response.isExisting = false;
            response.message = '';
            return response;
        }

        // To handle existing user
        if (data && !data.is_new_user) {
            response.isValid = true;
            response.isExisting = true;
            response.message = 'Account already exists';
            return response;
        }

        return response;
    } catch (error) {
        let message = `Something went wrong while verifying ${contactType}`;

        if (error.response.data.errors.message) {
            message = error.response.data.errors.message;
        }

        const toastPayload = {
            message
        };
        toasts.failureToast(toastPayload);

        const response = {
            isValid: false,
            isExisiting: false,
            message: ''
        };
        return response;
    }
};

const capitalizeFirstLetter = (string = null) => {
    if (string && string.length) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
    return '';
};

const slugify = (string) => {
    const specialCharacters =
        'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
    const characters =
        'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
    const specialCharactersRegex = new RegExp(specialCharacters.split('').join('|'), 'g');

    return (
        string
            .toString()
            .toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(specialCharactersRegex, (char) =>
                characters.charAt(specialCharacters.indexOf(char))
            ) // Replace special characters
            .replace(/&/g, '-and-') // Replace & with 'and'
            // eslint-disable-next-line no-useless-escape
            .replace(/[^\w\-]+/g, '') // Remove all non-word characters
            .replace(/--+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, '')
    ); // Trim - from end of text
};

const deSlug = (string) => {
    return string
        .toString()
        .replace(/-+/g, ' ')
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
};
const capitaliseFirst = (string) => {
    if (!string) {
        return string;
    }
    const stringArr = string.toLowerCase().split(' ');
    let newString = '';
    for (const word of stringArr) {
        if (word && word.length) {
            newString = newString + ' ' + word[0].toUpperCase() + word.substring(1);
        }
    }

    return newString;
};

const localCurrencyFormat = (price, format = 'en-IN') => {
    return new Intl.NumberFormat(format).format(price);
};

// b64EncodeUnicode
const encodeString = (str) => {
    return btoa(encodeURIComponent(str));
};

//   UnicodeDecodeB64
const decodeString = (str) => {
    return decodeURIComponent(atob(str));
};

const copyText = (textToCopy, toastMessage) => {
    const text = textToCopy;

    if (navigator.clipboard) {
        navigator.clipboard.writeText(text);
    } else {
        const textArea = document.createElement('textarea');
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.position = 'fixed';

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        document.execCommand('copy');

        document.body.removeChild(textArea);
    }

    const toastPayload = {
        message: toastMessage
    };
    toasts.successToast(toastPayload);
};

const isFeatureAllowed = (permission, permissionList) => {
    for (const index in permissionList) {
        if (permissionList[index].key === permission) {
            return true;
        }
    }
    return false;
};
const loadMathjax = () => {
    if (!document.getElementById('mathjaxConfig') && !document.getElementById('mathjax')) {
        const mathjaxConfig = document.createElement('script');
        mathjaxConfig.id = 'mathjaxConfig';
        mathjaxConfig.type = 'text/x-mathjax-config';
        mathjaxConfig.innerHTML = `
            MathJax.Hub.Config({
                tex2jax: {
                    inlineMath: [['$','$']],
                    processEscapes: true
                },
                messageStyle: "none"
            });
        `;
        document.head.appendChild(mathjaxConfig);

        const mathjax = document.createElement('script');
        mathjax.id = 'mathjax';
        mathjax.type = 'text/javascript';
        mathjax.src =
            'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/MathJax.js?config=TeX-AMS_CHTML';
        document.head.appendChild(mathjax);
    }
};

// NOTE: Can only download files hosted on OUR DOMAIN else it will give cors error
const downloadPdfFromUrl = (pdfUrl, pdfName) => {
    fetch(pdfUrl)
        .then((response) => response.blob()) // Get the response as a Blob
        .then((blob) => {
            // Create a download link for the Blob
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${pdfName}.pdf`; // Set the desired file name

            // Trigger a click event on the link to initiate the download
            link.click();
        })
        .catch((error) => {
            console.error('Error downloading PDF:', error);
        });
};

const truncateHtml = (html, length) => {
    let currentCharacters = 0;
    const temporaryElement = document.createElement('div');
    temporaryElement.innerHTML = html;

    function cloneAndAppend(node) {
        const clonedNode = node.cloneNode(false);

        for (let index = 0; index < node.childNodes.length; index++) {
            const childNode = node.childNodes[index];
            const remainingCharacters = length - currentCharacters;

            if (childNode.nodeType === Node.TEXT_NODE) {
                const textContent = childNode.textContent || '';
                if (textContent.length <= remainingCharacters) {
                    currentCharacters += textContent.length;
                    clonedNode.appendChild(childNode.cloneNode(true));
                } else {
                    const truncatedText = textContent.substring(0, remainingCharacters);
                    clonedNode.appendChild(document.createTextNode(truncatedText));
                    currentCharacters += remainingCharacters;
                    break;
                }
            } else {
                const childClone = cloneAndAppend(childNode);
                clonedNode.appendChild(childClone);
            }
        }

        return clonedNode;
    }

    const clonedRoot = cloneAndAppend(temporaryElement);
    return clonedRoot.innerHTML;
};

function isBotUserAgent() {
    const agent = window.navigator.userAgent;

    const botUserAgentsArray = [
        'googlebot',
        'bingbot',
        'yahoo',
        'baiduspider',
        'yandexbot',
        'duckduckbot',
        'facebookexternalhit',
        'twitterbot',
        'linkedinbot',
        'slackbot',
        'discordbot',
        'applebot',
        'pinterestbot',
        'instagram',
        'lighthouse',
        'insights'
    ];

    let isBotUserAgent = false;
    for (let idx = 0; idx < botUserAgentsArray.length; idx++) {
        if (agent.toLowerCase().includes(botUserAgentsArray[idx].toLowerCase())) {
            // console.log('botUserAgent found: ' + botUserAgentsArray[idx].toLowerCase());
            isBotUserAgent = true;
            break;
        }
    }

    return isBotUserAgent;
}
const getProfilePic = (name, bg = 'F5F3FF', color = '6002D8') => {
    const [fname, lname] = name.split(' ');
    return (
        `https://ui-avatars.com/api/?background=${bg}&color=${color}&name=` +
        (fname && lname ? fname + '+' + lname : fname)
    );
};
const getBaseUrl = () => {
    let baseUrl = '';
    switch (process.env.SERVER_ENV) {
        case 'local': {
            baseUrl = 'http://dev.localhost.com:3000';
            break;
        }
        case 'localsecure': {
            baseUrl = 'https://dev.localhost.com:3000';
            break;
        }
        case 'dev': {
            baseUrl = 'https://devprep.yocket.com';
            break;
        }
        case 'alpha': {
            baseUrl = 'https://alphaprep.yocket.com';
            break;
        }
        case 'beta': {
            baseUrl = 'https://betaprep.yocket.com';
            break;
        }
        case 'prod': {
            baseUrl = 'https://prep.yocket.com';
            break;
        }
        default: {
            baseUrl = 'https://prep.yocket.com';
        }
    }
    return baseUrl;
};
const getFullUrl = (path) => {
    const baseUrl = getBaseUrl();
    return `${baseUrl}${path}`;
};

const getAcquisitionDetails = (route) => {
    if (process.client) {
        const landingUrl = localStorage.getItem('acquisition_landing_url') ?? '';
        const signupUrl = route.fullPath;
        return {
            landing_url: landingUrl,
            signup_url: signupUrl
        };
    }
    return {
        landing_url: '',
        signup_url: ''
    };
};

export {
    install as default,
    toasts as toast,
    isEmailValid,
    isPhoneValid,
    validateContact,
    capitalizeFirstLetter,
    localCurrencyFormat,
    encodeString,
    decodeString,
    copyText,
    slugify,
    deSlug,
    capitaliseFirst,
    isFeatureAllowed,
    downloadPdfFromUrl,
    loadMathjax,
    truncateHtml,
    isBotUserAgent,
    getProfilePic,
    getBaseUrl,
    getFullUrl,
    getAcquisitionDetails
};
