// NOT BEING USED, MOVED TO MIDDLEWARE. KEEPING HERE FOR REFERENCE
// import { PERMISSIONS } from '~/static/constants.js';

import { DASHBOARD_MODES, PLATFORM_FEATURES } from '~/static/constants';
import { ROUTES } from '~/constants/pageRoutes.js';
import { isFeatureAllowed } from '~/services/utilityFunctions';
import { EXAM_TYPES } from '~/constants/generalConstants';

export default async ({ $device, store, app: { router } }) => {
    // get the logged in user data on app load
    await store.dispatch('auth/getLoggedInUser');
    if (
        !store.getters['auth/isAuthenticated'] ||
        (store.getters['auth/isAuthenticated'] && !store.getters['auth/getUser'].country_code)
    ) {
        await store.dispatch('auth/getIpCountry');
    }

    router.beforeEach((to, from, next) => {
        const isInstructionsModalOpen = store.getters['testSets/showInstructions'];
        const isUserLoggedIn = store.getters['auth/isAuthenticated'];
        const hasGivenQuickDiagnostic = store.getters['auth/hasGivenQuickDiagnostic'];
        const hasGivenFullDiagnostic = store.getters['auth/hasGivenFullDiagnostic'];
        const isDashboardSetup = store.getters['auth/isDashboardSetup'];
        const recentQuickDiagId = store.getters['auth/recentQuickDiagId'];
        const recentFullDiagId = store.getters['auth/recentFullDiagId'];
        const isPremiumUser = store.getters['auth/isPremiumUser'];
        const canChangeRoute = store.getters['questions/canChangeRoute'];
        const isLiveClassUpgradeAvailable = store.getters['auth/isLiveClassUpgradeAvailable'];
        const isClassesAccessible = store.getters['auth/isClassesAccessible'];
        // const userPremiumFeatures = store.getters['auth/getUserPremiumFeatures'];
        const allowPlannerEdit = store.getters['priorityPlanner/allowPlannerEdit'];
        const userCountry = store.getters['auth/userCountry'];
        const selfPacedCourseAccess = store.getters['auth/selfPacedCourseAccess'];
        const hasPrepFreebies = store.getters['auth/hasPrepFreebies'];
        const isFreeTrialExpired = store.getters['auth/isFreeTrialExpired'];
        const featurePermissions = store.getters['auth/featurePermissions'];
        const profileCompletionLevel = store.getters['auth/profileCompletionLevel'];
        const testType = store.getters['auth/testType'];

        // Planner Related Info
        const isPlannerSet = store.getters['auth/getPlannerVersion'];

        // If the user logged in as a GMAT user after taking gre quick diagnostic test then redirects to gmat guide page
        if (testType === EXAM_TYPES.GMAT && to.path === ROUTES.PERFORMANCE) {
            router.push(ROUTES.GMAT_BLOGS_AND_GUIDES);
            next();
            return;
        }
        // If quick diagnostic test has already given then redirect to dashboard page
        if (hasGivenQuickDiagnostic && to.path === ROUTES.GRE_QUICK_DIAGNOSTIC) {
            router.push(ROUTES.DASHBOARD);
            next();
            return;
        }
        // close instruction modal on going back
        if (isInstructionsModalOpen) {
            store.commit('testSets/setShowInstructions', false);
            next(false);
        }

        // Non-premium user or upgrade available => redirect them to landing page
        if ((!isPremiumUser || isLiveClassUpgradeAvailable) && to.path === ROUTES.CLASSES) {
            router.push(ROUTES.CLASSES_LP);
        }

        // International Premium w/o any class access => redirect to dashboard
        if (
            isPremiumUser &&
            to.path.includes('classes') &&
            userCountry !== 'IN' &&
            !isClassesAccessible
        ) {
            router.push(ROUTES.DASHBOARD);
        }

        // On demand Plan => redirect to dashboard and switch mode to learning
        if (selfPacedCourseAccess && to.path === ROUTES.CLASSES) {
            router.push(ROUTES.DASHBOARD);

            if (process.client)
                store.commit('dashboard/setDashboardMode', DASHBOARD_MODES.LEARNING);
        }
        // Live Class Plan => redirect to classes
        else if (
            isPremiumUser &&
            [ROUTES.CLASSES_LP].includes(to.path) &&
            !isLiveClassUpgradeAvailable
        ) {
            router.push(ROUTES.CLASSES);
        }

        if (
            $device.isMobileOrTablet &&
            [ROUTES.PLANNER_LP, ROUTES.PLANNER, '/referral', ROUTES.VOCAB].includes(to.path)
        )
            router.push(ROUTES.HOME);

        // If the user is on questions page but the data isnt available then redirect to home
        // Can happen on reload on questions page
        if (to.path.includes(ROUTES.QUESTIONS) && !store.state.questions.currTestSetId) {
            router.push(ROUTES.HOME);
        }

        // beforeRouteEnter guard for questions screen
        if (
            [ROUTES.PERFORMANCE, ROUTES.LOGIN].includes(from.path) &&
            to.path.includes(ROUTES.QUESTIONS)
        ) {
            if (!canChangeRoute) {
                router.push(ROUTES.HOME);
            }
        }

        // If freebies are not available to the user, redirect to home
        if (
            to.path === ROUTES.FREEBIES &&
            (isPremiumUser || !hasPrepFreebies || isFreeTrialExpired)
        ) {
            router.push(ROUTES.HOME);
        }

        if (!isUserLoggedIn) {
            if ([ROUTES.DASHBOARD, ROUTES.PROFILE].includes(to.path)) router.push(ROUTES.HOME);
            else if (to.name === 'priority-planner-plan' || from.name === 'priority-planner-plan')
                router.push(ROUTES.PLANNER_LP);
            else if (to.name === 'premium-pay-planSlug' || from.name === 'premium-pay-planSlug')
                router.push(ROUTES.PRICING);
            else if (from.fullPath === ROUTES.HOME && to.fullPath.includes('/performance?ttid'))
                router.push(ROUTES.HOME);
            next();
            return;
        }

        // Checks for logged in user only
        const routeFeatureMapping = [
            { routes: [ROUTES.HOME, ROUTES.DASHBOARD], feature: PLATFORM_FEATURES.DASHBOARD },
            {
                routes: [ROUTES.VOCAB, ROUTES.VOCAB_PRACTICE, ROUTES.VOCAB_REPORT],
                feature: PLATFORM_FEATURES.VOCAB_BUILDER
            },
            {
                routes: [ROUTES.PLANNER_LP, ROUTES.PLANNER],
                feature: PLATFORM_FEATURES.STUDY_PLANNER
            },
            {
                routes: [ROUTES.ON_DEMAND, ROUTES.ON_DEMAND_V2],
                feature: PLATFORM_FEATURES.ON_DEMAND_LANDING_PAGE
            },
            { routes: [ROUTES.CLASSES_LP], feature: PLATFORM_FEATURES.LIVE_CLASS_LANDING_PAGE },
            { routes: [ROUTES.PRICING, ROUTES.PAYMENT], feature: PLATFORM_FEATURES.PRICING }
        ];
        const isRestrictedRoute = (path, featurePermissions) => {
            return routeFeatureMapping.some(
                ({ routes, feature }) =>
                    routes.includes(path) && !isFeatureAllowed(feature, featurePermissions)
            );
        };
        if (profileCompletionLevel === 2 && isRestrictedRoute(to.path, featurePermissions)) {
            router.push(ROUTES.GMAT_BLOGS_AND_GUIDES);
            next();
            return;
        }

        if (to.path === ROUTES.FREE_STARTER_KIT) {
            router.push(ROUTES.FREEBIES);
            next();
            return;
        }

        if (isPremiumUser) {
            if (
                [ROUTES.PRICING, ROUTES.ON_DEMAND, ROUTES.ON_DEMAND_V2].includes(to.path) ||
                (to.path.includes(ROUTES.PAYMENT) && !isLiveClassUpgradeAvailable)
            )
                router.push(ROUTES.DASHBOARD);
        } else if (to.path === '/referral') {
            router.push(ROUTES.HOME);
        }

        // CASE 1 - FD taken
        if (hasGivenFullDiagnostic) {
            if ($device.isMobileOrTablet) {
                // Only allow home and performance page on mobile device
                if ([ROUTES.LOGIN, ROUTES.DASHBOARD, ROUTES.PLANNER_LP].includes(to.path))
                    router.push(ROUTES.HOME);
            }
            // FD dashboard not set then take to performance page else on dashboard if profileCompletionLevel is 2
            else if (!isDashboardSetup && [ROUTES.HOME, ROUTES.LOGIN].includes(to.path)) {
                router.push({ path: ROUTES.PERFORMANCE, query: { ttid: recentFullDiagId } });
            } else if (
                [ROUTES.HOME, ROUTES.LOGIN].includes(to.path) &&
                profileCompletionLevel === 2
            )
                router.push(ROUTES.DASHBOARD);
        }

        // CASE 2 - QD taken but dashboard not set
        else if (hasGivenQuickDiagnostic && !isDashboardSetup) {
            if ($device.isMobileOrTablet) {
                // Only allow home and performance page on mobile device
                if ([ROUTES.LOGIN, ROUTES.DASHBOARD, ROUTES.PLANNER_LP].includes(to.path)) {
                    router.push(ROUTES.HOME);
                }
            }
            // Take to performance page as dashboard is not set
            else if ([ROUTES.HOME, ROUTES.LOGIN].includes(to.path)) {
                router.push({ path: ROUTES.PERFORMANCE, query: { ttid: recentQuickDiagId } });
            }
        }

        // CASE 3 - QD or Default dashboard is set
        else if (!hasGivenQuickDiagnostic || isDashboardSetup) {
            if ($device.isMobileOrTablet) {
                // Only allow home and performance page on mobile device
                if ([ROUTES.LOGIN, ROUTES.DASHBOARD, ROUTES.PLANNER_LP].includes(to.path)) {
                    router.push(ROUTES.HOME);
                }
            }
            // Take to dashboard as it will always exist and if profileCompletionLevel is 2.
            else if ([ROUTES.HOME, ROUTES.LOGIN].includes(to.path) && profileCompletionLevel === 2)
                router.push(ROUTES.DASHBOARD);
        }

        // Logged In Planner Related Actions
        /**
         * If Planner Version exists, never allow user to visit planner landing page unless code allows
         */
        if (isPlannerSet && !allowPlannerEdit && to.path === ROUTES.PLANNER_LP) {
            router.push(ROUTES.PLANNER);
        }
        if (!isPlannerSet && to.path === ROUTES.PLANNER) {
            router.push(ROUTES.PLANNER_LP);
        }

        next();
    });
};
