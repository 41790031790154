export default ({ app }) => {
    if (process.client) {
        try {
            if (!localStorage.getItem('acquisition_landing_url')) {
                // Store the initial landing URL if it hasn't been set yet
                localStorage.setItem(
                    'acquisition_landing_url',
                    location.pathname + location.search
                );
            }
        } catch (error) {
            console.error('Error setting acquisition landing URL:', error);
        }
    }
};
